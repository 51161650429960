<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12"></div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('pet_dropdown_menu_diary_routine')"
          :to1="'/petownerdiary/dashboard'"
          titlemenu2="Bulk Routine Overview"
          titlemenu3="Edit Bulk Routine"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center items-center">
      <RoutineTable />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import RoutineTable from "./RoutineTable.vue";

export default {
  components: {
    HeaderGlobal,
    RoutineTable,
  },
};
</script>

<style>
</style>