<template>
  <div>
    <span v-if="!textImage">{{ pet.callName }}</span>
    <img v-else class="rounded-full m-1 bg-cover h-8 w-8" :src="img" :alt="pet" />
  </div>
</template>
<!-- species -->

<script>
import { petPicture } from "../../methods/petMethods";
export default {
  props: ["pet"],
  data() {
    return {
      img: null,
    };
  },
  async created() {
    this.img = petPicture(this.pet);
  },
  computed: {
    textImage() {
      if (typeof this.pet == "object") {
        return true;
      } else {
        return false;
      }
    },
  },
  
};
</script>

<style>
</style>