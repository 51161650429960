<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center"
        >
          <div class="w-10/12 modal-body mt-5 mb-0">
            <!-- <h3 class="text-base">{{ $t("delete_account_question") }}</h3> -->
            <div class="text-base font-proximaMedium">
              <h1 class="text-2xl">
                {{ title }}
              </h1>
            </div>
            <!-- <h5 class="mt-2 text-xs font-proximaLight">
                {{ $t("delete_account_text") }}
              </h5> -->
            <div class="mt-2 text-xs font-proximaLight">
              <div
                slot="actionText"
                class="flex flex-col items-center justify-center text-sm font-proximaLight"
              >
                <span class="my-2"
                  >Add activity to the care routine of multiple pet records.
                </span>
                <div
                  class="h-52 w-full md:w-9/12 overflow-scroll overflow-x-hidden"
                >
                  <div
                    class="my-2 flex items-center justify-between"
                    v-for="(contact, i) in contactsComp"
                    :key="i"
                  >
                    <div class="flex items-center">
                      <img
                        class="rounded-full w-9 h-9"
                        :src="contact.profile_picture"
                        alt="profilepic-icon"
                      />
                      <span class="ml-2">{{ contact.callName }}</span>
                    </div>
                    <RadioOnoffBorder
                      :checked="contact.checked"
                      :checkAll="checkAll"
                      :val="contact"
                      @inputVal="inputVal"
                    />
                  </div>
                </div>
                <div
                  class="w-10/12 md:w-9/12 mt-4 flex items-center justify-end"
                >
                  <label class="cont">
                    <input type="checkbox" @change="checkAllFunc($event)" />
                    <span class="checkmark"></span>
                  </label>
                  <span class="font-proximaLight text-md">Select all</span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal-footer w-full flex md:flex-row justify-center items-center"
          >
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <BorderedButton
                slot="leftBtn"
                class="md:mr-1"
                :title="$t('global_cancel')"
                borderColor="border-mediumGrey"
                textCl="text-darkGrey"
                paddingX="px-8"
                width="w-full"
                @click.native="$emit('close')"
              />
            </div>
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <SubmitButton
                v-if="valideButton"
                slot="rightBtn"
                class="md:ml-1"
                :title="$t('global_confirm')"
                background="bg-success"
                paddingX="px-8"
                width="w-full"
                @click.native="submit"
              />
              <SubmitButton
                v-else
                slot="rightBtn"
                class="md:ml-1 cursor-not-allowed"
                :title="$t('global_confirm')"
                background="bg-lightGrey"
                paddingX="px-8"
                width="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
  
  <script>
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
    title: String,
  },
  components: {
    SubmitButton,
    BorderedButton,
    RadioOnoffBorder,
  },
  data() {
    return {
      checkAll: false,
      valideButton: false,
    };
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
    }),

    contactsComp() {
      return this.petRecords
        ? this.petRecords.filter(
            (item) => item.type == 1 || item.type == 2 || item.type == 3
          )
        : [];
    },
  },

  async created() {
    this.getPetRecords(localStorage.getItem("email"));
  },
  methods: {
    ...mapActions({
      getPetRecords: "petRecord/getPetRecords",
      updatePetRecord: "petRecord/updatePetRecord",
    }),

    checkAllFunc(e) {
      let t = !this.contactsComp[0].checked;
      this.contactsComp.forEach((item) => {
        item["checked"] = t;
        this.valideButton = t;
        //this.updatePetRecord(item);
      });
      // this.checkAll = e.originalTarget.checked;
    },

    inputVal(contact, checked) {
      contact.checked = checked;
      let nbVal = this.contactsComp.filter(
        (item) => item.checked == true
      ).length;
      this.valideButton = nbVal > 0 ? true : false;

      //this.updatePetRecord(contact);
    },
    submit() {
      console.log(this.contactsComp);

      this.$emit("addPet", this.contactsComp);
    },
  },
};
</script>
  
  <style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>