<template>
  <div>
    <ul
      class="flex font-proximaLight border-r border-l border-lightGrey20"
      :class="{ 'bg-white': routine.id != 0 }"
    >
      <li
        class="border-r border-b border-lightGrey py-4 w-2/12 flex items-center justify-evenly"
      >
        <span v-if="!editActivityTime">{{ activityTime }}</span>
        <date-picker
          v-else
          class="px-2"
          v-model="activityTime"
          type="time"
          format="HH:mm"
          value-type="format"
          default-value="activityTime"
          @change="onChangeTime()"
        ></date-picker>
        <img
          rel="prefetch"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
          @click="editActivityTime = !editActivityTime"
          :class="{ 'pr-2': editActivityTime }"
        />
      </li>
      <li
        class="border-r border-b border-lightGrey py-4 w-2/12 flex items-center justify-evenly"
      >
        <div class="mx-2 flex items-center flex-wrap">
          <div v-for="(pet, i) in pets" :key="i">
            <RoutinePetsImage :pet="pet" />
          </div>
        </div>
      </li>
      <li
        class="border-r border-b border-lightGrey p-4 w-3/12 flex items-center justify-between"
      >
        <div class="flex items-center">
          <div
            class="w-max rounded-full p-2 mr-2"
            :class="timeSensibilityClass"
          ></div>
          <span :class="timeSensibilityTextClass" v-if="!editActivityText">{{
            activityText
          }}</span>
          <input
            v-else
            type="text"
            v-model="activityText"
            @change="onChangeActivity()"
            class="w-9/12 px-2"
            @keyup.enter="editActivityText = false"
          />
        </div>
        <img
          rel="prefetch"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
          @click="editActivityText = !editActivityText"
        />
      </li>
      <li
        class="border-b border-lightGrey py-4 w-5/12 flex items-center justify-between"
      >
        <p v-if="!editNotes" class="w-9/12 pl-2 text-left text-sm">
          {{ routineNotes }}
        </p>
        <textarea
          class="w-9/12 mx-2 p-1 text-sm"
          v-else
          v-model="routineNotes"
          @change="onChangeNote()"
        ></textarea>
        <div class="w-3/12 flex justify-evenly items-center">
          <img
            rel="prefetch"
            src="@/assets/images/edit-icon.png"
            alt="edit-icon"
            @click="editNotes = !editNotes"
          />
          <TrashIcon
            v-if="routine.id != 0"
            width="19"
            height="19"
            iconColor="#fff"
            bgColor="#0AC0CB"
            class="cursor-pointer"
            @click.native="deleteActModals = !deleteActModals"
          />
        </div>
      </li>
    </ul>

    <!-- delete activity modals -->
    <ModalsConfirm :show="deleteActModals" @close="deleteActModals = false">
      <h1 slot="actionQuestion" class="mt-4 text-2xl">Delete activity?</h1>
      <div
        slot="actionText"
        class="my-2 flex items-center justify-center text-sm font-proximaLight"
      >
        <span class="text-red pr-1">{{ $t("global_attention") }} </span>
        <span>{{ $t("routine_table_remove_msg") }}</span>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="deleteActModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-red"
        paddingX="px-8"
        width="w-full"
        @click.native="deleteRoutine(routine)"
      />
    </ModalsConfirm>
    <!-- delete activity modals end -->
  </div>
</template>

<script>
import TrashIcon from "../icons/TrashIcon.vue";
import RoutinePetsImage from "./RoutinePetsImage.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { petRecordById } from "../../services/petRecordService";

export default {
  components: {
    TrashIcon,
    RoutinePetsImage,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    DatePicker,
    RadioOnoffBorder,
  },
  props: ["routine"],
  data() {
    return {
      bulkShareModals: false,
      pets: [],
      img: null,
      checkAll: false,
      deleteActModals: false,
      editNotes: false,
      editActivityText: false,
      editActivityTime: false,
      time: null,
      id: this.routine.id,
      activityTime: this.routine.activity_time,
      routineNotes: this.routine.notes,
      activityText: this.routine.activity,
      timeSensibility: this.routine.time_sensitivity,
      timeSensibilityClass:
        this.routine.time_sensitivity == 1
          ? "bg-red"
          : this.routine.time_sensitivity == 2
          ? "bg-orange"
          : "bg-primary",
      timeSensibilityTextClass:
        this.routine.time_sensitivity == 1
          ? "text-red"
          : this.routine.time_sensitivity == 2
          ? "text-orange"
          : "text-primary",
      contacts: [
        /*  {
          id: "1",
          name: "Pet 1",
          checked: false,
        },
        {
          id: "2",
          name: "Pet 2",
          checked: false,
        },
        {
          id: "3",
          name: "Pet 3",
          checked: false,
        },
        {
          id: "4",
          name: "Pet 4",
          checked: true,
        },
        {
          id: "5",
          name: "Pet 5",
          checked: false,
        },
        {
          id: "6",
          name: "Pet 6",
          checked: false,
        },
        {
          id: "7",
          name: "Pet 7",
          checked: false,
        },
        {
          id: "8",
          name: "Pet 8",
          checked: false,
        },
        {
          id: "9",
          name: "Pet 9",
          checked: false,
        },
        {
          id: "10",
          name: "Pet 10",
          checked: false,
        }, */
      ],
    };
  },
  /* watch: {
    routine: function () {
      alert(this.routine.id);
    },
  }, */
  async created() {
    this.routine.pets.forEach(async (item, index) => {
      const { data } = await petRecordById(item);
      const petRecord = data.petRecord;

      this.pets.push(petRecord);
    });
  },
  watch: {
    routine: function () {
      this.timeSensibilityClass =
        this.routine.time_sensitivity == 1
          ? "bg-red"
          : this.routine.time_sensitivity == 2
          ? "bg-orange"
          : "bg-primary";
      this.timeSensibilityTextClass =
        this.routine.time_sensitivity == 1
          ? "text-red"
          : this.routine.time_sensitivity == 2
          ? "text-orange"
          : "text-primary";
    },
  },
  methods: {
    async deleteRoutine(r) {
      console.log(r);
      this.$emit("deleteRout", r);

      this.deleteActModals = false;
    },
    onChangeActivity() {
      this.routine.activity = this.activityText;
      this.$emit("editRout", this.routine);
    },

    onChangeTimeSensitivity(i) {
      console.log(i);
      this.timeSensibility = i;
    },

    confirmTimeSensibility() {
      this.routine.time_sensitivity = this.timeSensibility;
      this.$emit("editRout", this.routine);

      this.timeSensibilityClass =
        this.routine.time_sensitivity == 1
          ? "bg-red"
          : this.routine.time_sensitivity == 2
          ? "bg-orange"
          : "bg-primary";

      this.bulkShareModals = false;
    },

    onChangeTime() {
      this.routine.activity_time = this.activityTime;
      this.$emit("editRout", this.routine);
    },

    onChangeNote() {
      this.routine.notes = this.routineNotes;
      this.$emit("editRout", this.routine);
    },

    closeeditmode() {
      this.editNotes = false;
      this.editActivityText = false;
      this.editActivityTime = false;
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>