<template>
  <div class="w-full md:w-11/12 lg:w-10/12">
    <h1 class="ml-4 md:ml-0 text-left font-proximaMedium font-bold text-2xl">
      {{ $t("pet_record_overview_routine_overview") }}
    </h1>
    <Loader class="mt-6 flex justify-center" v-if="showLoader" />
    <div class="w-1000 md:w-full" v-else>
      <FormError :errors="errors.save" />
      <FormError :errors="errors.activityTime" />
      <FormError :errors="errors.activity" />
      <FormError :errors="errors.notes" />

      <div
        class="py-6 px-4 mt-4 bg-primary rounded-tl-md rounded-tr-3xl font-proximaMedium text-white"
      >
        <ul class="flex">
          <li class="w-2/12">{{ $t("global_time") }}</li>
          <li class="w-2/12">{{ $t("global_pets") }}</li>
          <li class="w-3/12">{{ $t("global_activity") }}</li>
          <li class="w-5/12">{{ $t("global_notes") }}</li>
        </ul>
      </div>
      <div v-for="(routine, i) in routinesComp1" :key="i">
        <RoutineTableEach
          :routine="routine"
          @deleteRout="deleteRoutine"
          @editRout="editRoutine"
          ref="child"
        />
      </div>
    </div>

    <div
      class="w-full mt-4 px-8 flex justify-between"
      v-if="showLoader == false"
    >
      <!-- @click.native="bulkShareModals = !bulkShareModals" -->

      <AddIcon
        v-if="!activityAdded"
        class="w-14 h-14 cursor-pointer"
        @click.native="submitAddIcon"
      />
      <div v-else></div>
      <div>
        <!-- FIXME: 1091 : the individual care routine / not fix just to refer -->
        <SubmitButton
          v-if="activityAdded"
          :title="$t('account_save_button')"
          background="bg-primary"
          paddingX="px-8"
          marginT="mt-2"
          width="w-max"
          @click.native="submitEditRoutine"
        />
        <SubmitButton
          v-else
          :title="$t('account_save_button')"
          class="cursor-not-allowed"
          background="bg-lightGrey"
          paddingX="px-8"
          marginT="mt-2"
          width="w-max"
          @click.native="submitEditRoutine"
        />
      </div>
    </div>

    <ModalsBulkShareRecords
      :title="$t('care_routine_bulk_share_records')"
      :show="bulkShareModalsComp"
      @close="closeBulkShareModals"
      @addPet="submitAddPets"
    />

    <ModalsConfirm :show="timeBasedModals" @close="timeBasedModals = false">
      <h1 slot="actionQuestion" class="mt-4 text-2xl">
        Select Time Sensitivity
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2"
          >Some activities are time sensitive and trigger reminders.<br />Please
          click on the the time sensitivity.
        </span>
        <div class="mt-2 w-full md:w-10/12 h-32 font-proximaMedium">
          <div class="flex">
            <div class="mr-1 bg-red w-5 h-5 rounded-full"></div>
            <InputRadioEachReverse
              title="must be done within 1 hour of the specified time"
              textCl="text-red"
              :id="1"
              :checked="false"
              @inputVal="onChangeTimeSensitivity"
            />
          </div>
          <div class="flex my-2">
            <div class="mr-1 bg-orange w-5 h-5 rounded-full"></div>
            <InputRadioEachReverse
              title="should be done within 3 hours of the specified time"
              textCl="text-orange"
              :id="2"
              :checked="false"
              @inputVal="onChangeTimeSensitivity"
            />
          </div>
          <div class="flex">
            <div class="mr-1 bg-primary w-5 h-5 rounded-full"></div>
            <InputRadioEachReverse
              title="not time sensitive, the time is a guideline. "
              textCl="text-primary"
              :id="3"
              :checked="false"
              @inputVal="onChangeTimeSensitivity"
            />
          </div>
        </div>
      </div>
      <!-- FIXME: 1091 when click cancel the new activity added  -->
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="timeBasedModals = false"
      />
      <SubmitButton
        v-if="confirmTimeSensibilitySelected"
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="confirmTimeSensibility"
      />
      <SubmitButton
        v-else
        slot="rightBtn"
        class="md:ml-1 cursor-not-allowed"
        :title="$t('global_confirm')"
        background="bg-lightGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="confirmTimeSensibility"
      />
    </ModalsConfirm>
  </div>
</template>

<script>
import RoutineTableEach from "./RoutineTableEach.vue";
import AddIcon from "../icons/AddIcon.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import ModalsBulkShareRecords from "../modals/ModalsBulkShareRecords.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";
import Loader from "../loader/Loader.vue";
import FormError from "../warning/FormError.vue";
import InputRadioEachReverse from "../forms/InputRadioEachReverse.vue";

import {
  getCareRoutineByUser,
  deleteRoutine,
  updateRoutine,
  createRoutine,
} from "../../services/careRoutineService";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    RoutineTableEach,
    AddIcon,
    SubmitButton,
    ModalsConfirm,
    ModalsBulkShareRecords,
    BorderedButton,
    RadioOnoffBorder,
    Loader,
    FormError,
    InputRadioEachReverse,
  },
  data() {
    return {
      showLoader: false,
      checkAll: false,
      errors: {},
      routines: [],
      timeBasedModals: false,
      timeSensibility: 0,
      data1: {},
      activityAdded: false,
      confirmTimeSensibilitySelected: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
    }),

    routinesComp1() {
      return this.$store.state.routinesState;
    },
    prevRoutePath() {
      console.log(this.prevRoute);
      return this.prevRoute ? this.prevRoute.path : "/";
    },
    contacts() {
      return this.petRecords
        ? this.petRecords.filter(
            (item) => item.type == 1 || item.type == 2 || item.type == 3
          )
        : [];
    },

    bulkShareModalsComp() {
      return this.$store.state.bulkShareModalsState;
    },
  },
  /*  watch: {
    routinesComp1: function () {
      alert('upda');
    },
  },
 */
  async created() {
    const lastPath = this.$router.options.history;

    this.showLoader = true;
    const email = localStorage.getItem("email");

    const acts = await getCareRoutineByUser(email);
    this.$store.commit("routinesComp", acts.data);
    this.getPetRecords(localStorage.getItem("email"));

    this.showLoader = false;
  },

  methods: {
    ...mapActions({
      getPetRecords: "petRecord/getPetRecords",
    }),
    onChangeTimeSensitivity(i) {
      console.log(i);
      this.timeSensibility = i;
      if (this.timeSensibility != 0) {
        this.data1.time_sensitivity = this.timeSensibility;
        this.confirmTimeSensibilitySelected = true;
      }
    },
    /* FIXME: if this.timeSensibilty ==0 button confirm is gray */
    async confirmTimeSensibility() {
      if (this.timeSensibility != 0) {
        const routs = [...this.$store.state.routinesState, this.data1];
        this.$store.commit("routinesComp", routs);
      }
      this.timeBasedModals = false;
      this.activityAdded = true;
    },
    async deleteRoutine(r) {
      console.log(r);
      //if (r.id != 0) {
      /*  const routines = this.$store.state.routinesState.filter(
          (a) => a.id !== r.id
        ); */
      /*  this.$store.commit(
        "routinesComp",
        this.$store.state.routinesState.filter((a) => a.id !== r.id)
      ); */
      await deleteRoutine(r.id).then(async () => {
        /*  const acts = await getCareRoutineByUser(localStorage.getItem("email"));
        this.$store.commit("routinesComp", acts.data); */
        location.reload();
      });
      //this.$store.commit("routinesComp", []);
      /*  } else {
        const rout = this.$store.state.routinesState.filter(
          (a) =>
            a.id !== r.id &&
            a.activity !== r.activity &&
            a.time_sensitivity !== r.time_sensitivity
        );
        console.log(rout);
        const routines = rout;
        this.$store.commit("routinesComp", []);

        this.$store.commit("routinesComp", routines);
      } */
    },

    submitAddIcon() {
      this.$store.commit("bulkShareModals", true);
      /*  this.contactsComp.forEach((item) => {
        item["checked"] = t;
        this.valideButton = t;
      });
 */
      /*  this.contacts.forEach(async (item) => {
        item["checked"] = false;
      }); */
    },

    editRoutine(routine) {
      console.log("8888");
      this.activityAdded = true;
      const routines = [...this.$store.state.routinesState];
      const index = routines.indexOf(routine);

      routines[index] = { ...routine };
      this.$store.commit("routinesComp", routines);
    },

    async submitAddPets(contacts) {
      console.log("---------", contacts);
      const vals = contacts.filter((p) => p.checked == true);
      console.log("vals : ", vals);
      if (vals.length !== 0) {
        const pets = [];
        vals.map((v) => pets.push(v.petID));
        console.log("pets -------- : ", pets);

        this.data1 = {
          id: 0,
          userEmailAddress: localStorage.getItem("email"),
          pets: pets,
          activity: "",
          activity_time: "",
          time_sensitivity: 3,
          notes: "",
          done: false,
          // time_done: "",
          comment: "",
        };
      }
      this.$store.commit("bulkShareModals", false);
      this.timeBasedModals = true;
    },
    validate(data) {
      const errors = {};

      if (data.activity_time === "")
        errors.activityTime = "Time may not be blank.";
      //"Time has wrong format. Use one of these formats instead: hh:mm[:ss[.uuuuuu]].";
      if (data.activity === "") errors.activity = "Activity may not be blank.";
      if (data.notes === "") errors.notes = "Notes may not be blank.";

      return Object.keys(errors).length === 0 ? null : errors;
    },

    submitEditRoutine() {
      console.log("this.routines--- : ", this.$store.state.routinesState);

      try {
        this.$store.state.routinesState.map(async (r, index) => {
          const errors = this.validate(r);
          this.errors = errors || {};
          console.log(this.errors);
          if (errors) return;

          if (r.id !== 0) {
            await updateRoutine(r);
            this.activityAdded = false;
          } else {
            try {
              const { data: rout } = await createRoutine(r);
              r.id = rout.id;

              //-----------
              const index = this.$store.state.routinesState.findIndex(
                (p) => p.id === rout.id
              );

              if (index !== -1) {
                const pet = this.$store.state.routinesState.filter(
                  (p) => rout.id === p.id
                );
                /* console.log(rout, this.$store.state.routinesState); */
                //console.log(index);
                const routines = [...this.$store.state.routinesState];
                const index = routines.indexOf(pet[0]);

                routines[index] = { ...rout };
                this.$store.state.routinesState = routines;
                this.$store.commit("routinesComp", routines);
                console.log(this.$store.state.routinesState);
                this.activityAdded = false;

                /* this.$store.state.routinesState.splice(index, 1);
                console.log(this.$store.state.routinesState);
                const routs = [...this.$store.state.routinesState, rout]; */
                console.log("++++++++ : ", this.$store.state.routinesState);
                //this.$store.commit("routinesComp", routs);
                //console.log(routs);
                //this.$store.state.routinesState.splice(index, 1);
              }
              //-----------

              // console.log(this.$store.state.routinesState);
            } catch (error) {
              if (error && error.response) {
                console.log("*******", error.response.data);
              }
            }
          }

          this.$refs.child[index].closeeditmode();
        });
      } catch (error) {
        if (error && error.response) {
          this.errors.save = "Error in save";
          console.log(error.response.data);
        }
      }
    },

    closeBulkShareModals() {
      this.$store.commit("bulkShareModals", false);
      this.checkAll = false;
    },
  },
};
</script>

<style></style>
